import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const ChevronRight = React.forwardRef<
  SVGElement,
  { fillColor?: string } & SvgProps
>(({ fillColor = '#212121', ...props }, ref) => {
  return (
    <Svg
      ref={ref}
      width={24}
      height={24}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M8.293 4.293a1 1 0 000 1.414L14.586 12l-6.293 6.293a1 1 0 101.414 1.414l7-7a1 1 0 000-1.414l-7-7a1 1 0 00-1.414 0z'
        fill={fillColor}
      />
    </Svg>
  )
})

export default ChevronRight
